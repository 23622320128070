import React, { FC } from 'react';
import { TeamContactField } from '../TeamContactField';
import styles from './styles.module.scss';

interface IProps {
  image: string | null;
  position: string;
  name: string;
  contacts: Record<string, string>;
}

const customHref: Record<string, string> = {
  skype: 'skype:',
  email: 'mailto:',
  phone: 'tel:',
  whatsapp: 'https://wa.me/',
  telegram: 'tg://resolve?domain=',
  dribbble: 'https://dribbble.com/',
  twitter: 'https://twitter.com/',
  github: 'https://github.com/',
  linkedin: 'https://www.linkedin.com/',
};

const getHref = (type: string, href: string | unknown): string =>
  `${customHref[type] ?? ''}${href}`;

const TeamPerson: FC<IProps> = ({ image, position, name, contacts }) => {
  const contactsArray = Object.entries(contacts);

  return (
    <div className={styles.person}>
      {image ? (
        <img
          className={styles.person__image}
          alt={name}
          src={`${process.env.GATSBY_STRAPI_API_URL}${image}`}
        />
      ) : (
        <svg viewBox="0 0 180 180" className={styles.person__image}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z"
            fill="#F8F8FA"
          />
        </svg>
      )}

      <div className={styles.person__name}>{name}</div>
      <div className={styles.person__title}>{position}</div>
      <div className={styles.person__contacts}>
        {contactsArray.map(
          ([network, contact]) =>
            contact && (
              <TeamContactField key={network} icon={network}>
                {getHref(network, contact)}
              </TeamContactField>
            )
        )}
      </div>
    </div>
  );
};

export { TeamPerson };
