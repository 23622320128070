import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import classnames from 'classnames';
import { MainLayout } from '../../templates/MainLayout';
import styles from './styles.module.scss';
import { YellowHeader } from '../../components/main/YellowHeader';
import { TeamPerson } from '../../components/team/TeamPerson';

type IProps = PageProps<ITeamPage>;

type ITeamPage = {
  strapiTeam: {
    id: string;
    locale: string;
    header: {
      title: string;
      subTitle: string;
    };
    seo: {
      seoTitle: string;
      seoDescription: string;
      seoKeywords: string;
    };
    employees: {
      id: string;
      firstName: string;
      lastName: string;
      position: string;
      email: string;
      phone: string;
      photo: {
        url: string;
      };
      social: {
        telegram: string;
        linkedin: string;
        skype: string;
        github: string;
        twitter: string;
        dribbble: string;
      };
    }[];
  };
};

const TeamPage: FC<IProps> = ({ data }) => {
  const { locale, header, seo, employees } = data.strapiTeam;

  return (
    <MainLayout
      locale={locale}
      title={seo.seoTitle}
      description={seo.seoDescription}
      keywords={seo.seoKeywords}
    >
      <YellowHeader header={header.title}>{header.subTitle}</YellowHeader>

      <div className={classnames('content', styles.wrap)}>
        <div className={styles.grid}>
          {employees &&
            employees.map((employee) => {
              const {
                id,
                firstName,
                lastName,
                position,
                email,
                phone,
                photo,
                social,
              } = employee;

              const contacts = { email, phone, whatsapp: phone, ...social };

              return (
                <TeamPerson
                  key={id}
                  name={`${firstName} ${lastName}`}
                  image={photo?.url}
                  position={position}
                  contacts={contacts}
                />
              );
            })}
        </div>
      </div>
    </MainLayout>
  );
};

export default TeamPage;

export const query = graphql`
  query {
    strapiTeam {
      id
      locale
      header {
        title
        subTitle
      }
      seo {
        seoTitle
        seoDescription
        seoKeywords
      }
      employees {
        id
        firstName
        lastName
        position
        email
        phone
        photo {
          url
        }
        social {
          telegram
          linkedin
          skype
          github
          twitter
          dribbble
        }
      }
    }
  }
`;
