import React, { FC } from 'react';
import { ICONS } from '../../../constants/icons';
import styles from './styles.module.scss';

interface IProps {
  children: string;
  icon: string;
}

const TeamContactField: FC<IProps> = ({ children, icon }) => (
  <a href={children} target="_blank" rel="nofollow noreferrer">
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.icon}
    >
      {ICONS[icon]}
    </svg>
  </a>
);

export { TeamContactField };
